<template>
  <div class="view view--adventices">
    <div class="view-header">
      <div class="view-header__footer">
        <h1 class="page-title">Stade des adventices</h1>
      </div>
    </div>
    <template v-if="!loading">
      <div class="view-body">
        <Section>
          <Container>
            <template v-if="notation">
              <SelectExtended
                v-for="cible in notation?.cibles"
                :key="cible.id"
                inline
                v-model="cibles[cible.id]"
                :label="cible.valeur"
                special="Sélectionner un stade"
                option-key="uid"
                option-value="designation"
                :items="stades"
                @update:modelValue="updateCible(cible.id, $event)"
              />
            </template>
          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <Btn text="Commencer les notations" color="primary" @click="begin()" />
        <Btn text="Annuler" :to="{ name: 'home' }" />
      </div>
    </template>
  </div>
  <Loader :active="loading" />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Loader from '@/components/layout/Loader.vue'

export default {
  name: 'AdventiceView',

  components: {
    Section,
    Container,
    Btn,
    SelectExtended,
    Loader,
  },
  props: {
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      frames: {},
      cibles: [],
      adventices: [],
      notation: null,
      stades: this.$store.state.app.stades,
      loading: true,
    }
  },

  mounted() {
    this.getNotation().then((res) => {
      this.notation = res
      if (!this.notation?.cibles || this.notation.cibles.length === 0) {
        this.begin()
      }

      this.adventices = this.notationService.getFromLocalStorage('adventices')
      this.adventices = this.adventices.filter(
        (ad) => ad.notation === this.notation.id,
      )
      this.adventices.forEach((ad) => {
        this.updateCible(ad.cible.id, ad.cible.stade, true)
      })
      this.loading = false
    })
  },
  methods: {
    getNotation() {
      return new Promise((resolve) => {
        const reservation = this.notationService.getItem('reservations', this.$route.params.id)
        resolve(
         reservation?.notation,
        )
      })
    },
    updateCible(id, data, init = false) {
      if (!init) {
        this.adventices.push({
          notation: this.notation.id,
          cible: {
            id,
            stade: data,
          },
        })
      }

      this.cibles[id] = data
    },
    begin() {
      this.notationService.setAdventices(this.adventices)
      this.$router.push({
        name: 'notation',
        params: {
          id: this.$route.params.id,
          localisation: 'terrain',
          etape:
            this.notation.frames.terrainIndex === 0
              ? this.notation.frames.terrainIndex + 1
              : this.notation.frames.terrainIndex,
          notationProps: JSON.stringify(this.notation),
        },
      })
    },

    getStade(cibleId) {
      let stade = { key: null, value: null }
      let data = this.adventices.filter(
        (ad) => parseInt(ad.cible.id, 10) === parseInt(cibleId, 10),
      )
      data = data.pop()

      if (data) {
        stade = data.cible.stade
      }
      return stade
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.input-block) {
  margin-bottom: $gutter-quarter !important;
}

::v-deep(label) {
  font-size: $font-size-big;
  font-weight: $font-weight-bold;
}

.view-footer {
  border-top: none;
  padding-top: 0;
  flex-wrap: wrap;
  gap: $gutter-half;

  > .btn {
    width: 100%;
  }
}
</style>
